<template>
    <b-sidebar
        id="mail-form-sidebar"
     :visible="isMailFormSidebarActive"
     bg-variant="white"
     sidebar-class="sidebar-lg"
     shadow
     backdrop
     no-header
     right
     @shown="getData(propertyData)"
    
     @change="(val) => $emit('update:is-mail-form-sidebar-active', val)"
    >
    <template #default="{ hide }"> 
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
           <h5 class="mb-0">
               Mail  Form
           </h5>
   
           <feather-icon
               class="ml-1 cursor-pointer"
               icon="XIcon"
               size="16"
               @click="hide"
           />
       </div>
       <validation-observer
         #default="{ handleSubmit }"
         ref="refFormObserver"
       >
       <b-form
           class="p-2"
           @submit.prevent="handleSubmit(onSubmit)"
           @reset.prevent="resetForm"
         >
         <b-row>
            <b-col md="6">
                <validation-provider
                      #default="validationContext"
                      name="to"
                      rules="required"
                    >
                    <b-form-group
                      label="To"
                      label-for="to"
                    >
                      <b-form-input
                        id="to"
                        v-model="Data.to"
                        autofocus
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="To"
                       
                      />
        
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
            </b-col>
            <b-col md="6">
                <validation-provider
                      #default="validationContext"
                      name="cc"
                      rules="min:0"
                    >
                    <b-form-group
                      label="CC"
                      label-for="cc"
                    >
                      <b-form-input
                        id="cc"
                        v-model="Data.cc"
                        autofocus
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="CC"
                       
                      />
        
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
            </b-col>
            <b-col md="12">
                <validation-provider
                      #default="validationContext"
                      name="template_id"
                      rules="min:0"
                    >
                    <b-form-group
                      label="Template"
                      label-for="cc"
                    >
                      <v-select
                            id="template_id"
                            v-model="Data.template_id"
                            label="title"
                            :reduce="(option) => option.id"
                            :options="templates"
                            @input="changeTemplate"
                        />
        
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
            </b-col>
            <b-col md="12">
                <validation-provider
                      #default="validationContext"
                      name="subject"
                      rules="required"
                    >
                    <b-form-group
                      label="Subject"
                      label-for="subject"
                    >
                      <b-form-input
                        id="subject"
                        v-model="Data.subject"
                        autofocus
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Subject"
                       
                      />
        
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
            </b-col>
            <b-col md="12">
                <validation-provider
                      #default="validationContext"
                      name="message"
                      rules="required"
                    >
                    <b-form-group
                      label="Message"
                      label-for="message"
                    >
                    <quill-editor :options="snowOption" v-model="Data.message" />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
            </b-col>
           
         </b-row>
         <div class="d-flex mt-2">
           <b-button
             v-ripple.400="'rgba(255, 255, 255, 0.15)'"
             variant="primary"
             class="mr-2"
             type="submit"
           >
             Send
           </b-button>
           <b-button
             v-ripple.400="'rgba(186, 191, 199, 0.15)'"
             type="button"
             variant="outline-secondary"
             @click="hide"
           >
             Cancel
           </b-button>
         </div>
         
         </b-form>
       </validation-observer>
    </template>

    </b-sidebar>
</template>
<script>
import axios from '@axios'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormCheckbox,BFormInvalidFeedback, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BImg,VBToggle,BSidebar,BButtonToolbar, BButtonGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { ref,onUnmounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email, password,confirmed } from '@validations'
 import formValidation from '@core/comp-functions/forms/form-validation'
import crmStoreModule from '@/views/crm/crmStoreModule'



import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
    components: {
        BCard, BRow, BCol, BFormInput, BFormInvalidFeedback, BFormCheckbox, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BImg,VBToggle,BSidebar,BButtonToolbar, BButtonGroup,
        ValidationProvider,
     ValidationObserver,
     BForm, BFormGroup,
     vSelect,
     quillEditor
     
    },
    directives: {
     Ripple,
   },
   model: {
     prop: 'isMailFormSidebarActive',
     event: 'update:is-mail-form-sidebar-active',
   },
   props: {
     isMailFormSidebarActive: {
       type: Boolean,
       required: true,
     },
    
     propertyData: {
       type: Object,
       required: false,
     },
   },
   data(){
    return {
      snowOption: {
        theme: 'snow',
      },
    }
 },
   setup(props, { emit }) {
    const toast = useToast()
    const CRM_STORE_MODULE_NAME = 'app-crm'
        if (!store.hasModule(CRM_STORE_MODULE_NAME)) store.registerModule(CRM_STORE_MODULE_NAME, crmStoreModule)
        onUnmounted(() => {
            if (store.hasModule(CRM_STORE_MODULE_NAME)) store.unregisterModule(CRM_STORE_MODULE_NAME)
        });
        
        const getData = async(data)=>{
            Data.value.to  = '';
           if(data){
            if(data.emails.length > 0){
               Data.value.to =  data.emails.map(entry => entry.email).join(',')


            }
           }else{
            Data.value.to  = '';
           }
            

        }
       const blankData = {
           to:'',
           cc:'',
           template_id:'',
           subject:'',
           message:''
       }
       const templates = ref([]);
       const Data = ref(JSON.parse(JSON.stringify(blankData)))
       const getTemplates= async()=>{
            let temps = await store.dispatch('app-crm/fetchtemplateData', {
                perpage: 50,
            offset:0,
                         });

              console.log('temps', temps);           
            templates.value = temps.data.data;
            

        }
        const  changeTemplate = ()=>{
            let index = templates.value.findIndex(e => e.id === Data.value.template_id);
            let temp = templates.value[index]
            Data.value.subject = temp.subject;
            Data.value.message = temp.message;
          //  console.log('template_id',temp);


        }
        
        getTemplates();
    const {
       refFormObserver,
       getValidationState,
       resetForm,
     } = formValidation(resetData)
     const resetData = () => {
        Data.value = JSON.parse(JSON.stringify(blankData))
    }
    
    const onSubmit = async ()=>{
        const  userData = JSON.parse(localStorage.getItem('userData'));
        
      /*  var templateParams = {
            subject: this.mail.subject,
            name: 'James',
            notes: 'Check this out!',
            message: message,
            code: this.input.code,
            properties:this.properties_selected,
            email:this.mail.mailto
        };*/
        let params = {
            to:Data.value.to,
            cc:Data.value.cc,
            //from:this.userData.name+' <'+this.userData.email+'>',
            from:'"'+userData.name+'" <'+userData.email+'>',
            subject:Data.value.subject,
            message: Data.value.message,
        }
        var data = JSON.stringify(params);
        let response = await axios.post('fbr-mail/v1/sendcontactus',data);
        if(response.data.success){
            toast({
                     component: ToastificationContent,
                     position: 'top-right',
                     props: {
                     title: `Send Mail`,
                     icon: 'BellIcon',
                     variant: 'success',
                     text: `Complete`,
                     },
                 });

        }else{
            toast({
                     component: ToastificationContent,
                     position: 'top-right',
                     props: {
                     title: `Send Mail`,
                     icon: 'BellIcon',
                     variant: 'danger',
                     text: `${res.data.message}`,
                     },
                 });
        }
        emit('refetch-data')
         emit('update:is-mail-form-sidebar-active', false)

    }
    
     return {
        refFormObserver,
       getValidationState,
       resetForm,

       onSubmit,
       Data,
       resetData,
       templates,
       getData,
       changeTemplate
     }
   }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>