import { ref, watch, computed, onUnmounted } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default function usePropertyList() {
  // Use toast
  const toast = useToast()
  const refPropertyListTable = ref(null)
  const propertyData = ref({});
  const isMailFormSidebarActive = ref(false);
  const isModalFilesSelectedShow = ref(false);
  const imagePosition = ref({});

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'Image', sortable: true },
    { key: 'invoiceStatus',label: 'Property', sortable: true },
    { key: 'client', sortable: true },
    { key: 'total', sortable: true, formatter: val => `$${val}` },
    { key: 'issuedDate', sortable: true },
    { key: 'balance', sortable: true },
    { key: 'actions' },
  ]
  const tableColumns_property = [
    { key: 'image', label: 'Image', sortable: false, tdClass:'tb-image'},
    { 
      key: 'property',
      label: 'Property', 
      sortable: false ,
      filterOptions: {
        enabled: true,
        placeholder: 'Search Name',
      },
     
      
    },
    { key: 'owner',label: 'Owner', sortable: false },
    { key: 'units',label: 'House No Unit', sortable: true },
    { key: 'floors',label: 'Floor', sortable: true },
    { key: 'type_name',label: 'Type', sortable: true },
    { key: 'buildiing',label: 'Building', sortable: true },
    { key: 'bedroom',label: 'Br', sortable: true },
    { key: 'sqm',label: 'Sqm', sortable: true },
    { key: 'rent_price',label: 'Rent', sortable: true },
    { key: 'sale_price',label: 'Sale', sortable: true },
    { key: 'actions' },
    
  
  ]
  const perPage = ref(25)
  const totalProperty = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(router.currentRoute.query.searchQuery)
  const unit = ref(router.currentRoute.query.unit)
  const floor = ref(router.currentRoute.query.floor? router.currentRoute.query.floor: '')
  const type = ref(router.currentRoute.query.type_name? router.currentRoute.query.type_name: '')
  const building = ref(router.currentRoute.query.building)
  const bedroom = ref(router.currentRoute.query.bedroom? Number(router.currentRoute.query.bedroom): null)
  const sqm = ref(router.currentRoute.query.sqm)
  const price_rent = ref(router.currentRoute.query.price_rent)
  const price_sale = ref(router.currentRoute.query.price_sale)
 const statusId = ref(router.currentRoute.query.statusId);
 const expire_c = ref(null);

  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const propertyfilter = ref(router.currentRoute.query.propertyfilter);
  const ownerfilter = ref(router.currentRoute.query.ownerfilter);

  const blankData = {
    bedroom: router.currentRoute.query.bedroom? Number(router.currentRoute.query.bedroom): '',
    zone_id: router.currentRoute.query.zone_id != null? router.currentRoute.query.zone_id: '',
    zones: router.currentRoute.query.zones? router.currentRoute.query.zones.split(',').map(Number): router.currentRoute.query.zone_id? [Number(router.currentRoute.query.zone_id)]: [],
    place_id:[],
    bts_id: router.currentRoute.query.bts_id != null? router?.currentRoute?.query?.bts_id: '',
          bts_ids: router.currentRoute.query.bts_ids? router.currentRoute.query.bts_ids.split(',').map(Number): router.currentRoute.query.bts_id? [Number(router.currentRoute.query.bts_id)]: [],
    mrt_id: router.currentRoute.query.mrt_id != null? router.currentRoute.query.mrt_id: '',
    mrt_ids: router.currentRoute.query.mrt_ids? router?.currentRoute?.query?.mrt_ids.split(',').map(Number): router.currentRoute.query.mrt_id? [Number(router.currentRoute.query.mrt_id)]: [],
    rent_min: router.currentRoute.query.rentPricemin? router.currentRoute.query.rentPricemin: '',
    rent_max: router.currentRoute.query.rentPricemax? router.currentRoute.query.rentPricemax: '',
    sale_min: router.currentRoute.query.salePricemin? router.currentRoute.query.salePricemin: '',
    sale_max: router.currentRoute.query.salePricemax? router.currentRoute.query.salePricemax: '',
    expire_start: router.currentRoute.query.expire_start? router.currentRoute.query.expire_start: '',
    expire_end: router.currentRoute.query.expire_end? router.currentRoute.query.expire_end: '',
    avl_start: router.currentRoute.query.avl_start? router.currentRoute.query.avl_start: '',
    avl_end: router.currentRoute.query.avl_end? router.currentRoute.query.avl_end: '',
    room_min: router.currentRoute.query.sqm_min? router.currentRoute.query.sqm_min: '',
    room_max: router.currentRoute.query.sqm_max? router.currentRoute.query.sqm_max: '',
    amn_id:[],
    fac_id: router.currentRoute.query.fac_id? router.currentRoute.query.fac_id.split(',').map(Number): []
 }
 const advfilter = ref(JSON.parse(JSON.stringify(blankData)))
  
  const dataMeta = computed(() => {
    const localItemsCount = refPropertyListTable.value ? refPropertyListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProperty.value,
    }
  })

  const refetchData = () => {
    
    refPropertyListTable.value.refresh()
  }
  const formatDatea= (date)=> {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }
  const saveSearch=()=>{
    

  }
  const updateData = (update)=>{
    var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
   
    month = ('00'+month).slice(-2);
    const update_date = [year, month, day].join('-');
    update.avaliable_date = update_date;
    
    if(update.expire_date){
      let expire_date = update.expire_date.split('T');
      update.expire_date =  expire_date[0];
    }
    store.dispatch('app-property/fetchUpdateProperty',update).then(response=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Update Success",
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      refetchData();
    }).catch((err)=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Error Update Property",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })

    })
   /* let data = JSON.stringify(update);
    axios.post('/property/v1/update_propertydate',data).then(response=>{
      if(response.data.success){
          this.$toast({
              component: ToastificationContent,
                props: {
                  title: 'Done!',
                  icon: 'BellIcon',
                },
            })

        }else{
          this.$toast({
              component: ToastificationContent,
                props: {
                  title: 'Error!',
                  icon: 'BellIcon',
                },
            })

        }
        refetchData();
    }).catch(()=>{
         this.$toast({
          component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'BellIcon',
            },
        })

    });*/

  }
  
  const deleteData = (id) => {
    //fetchDeleteProperty
    store.dispatch('app-property/fetchDeleteProperty',id).then(response=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Delete Success",
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      refetchData();
    }).catch((err)=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Error Delete Property",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })

    })
    
  }
  const actions = [];
  watch([currentPage, perPage, searchQuery, statusFilter, unit,floor, type, building, bedroom,sqm,price_rent,price_sale,propertyfilter,ownerfilter,statusId,expire_c], () => {

    let filter = {
      searchQuery:searchQuery.value,
      statusFilter:statusFilter.value,
      unit:unit.value,
      floor:floor.value,
      type_name:type.value,
      building:building.value,
      bedroom:bedroom.value,
      sqm:sqm.value,
      price_sale:price_sale.value,
      propertyfilter:propertyfilter.value,
      ownerfilter:ownerfilter.value,
      statusId:statusId.value
    }
    store.commit('propertyConfig/FILTER_PROPERTY',filter)
    refetchData()
  })
  const clearData = () => {
    const blankData = {
      bedroom:'',
      zones:[],
      place_id:[],
      bts_ids:[],
      mrt_ids:[],
      mrt_id: '',
      rent_min:'',
      rent_max:'',
      sale_min:'',
      sale_max:'',
      expire_start:'',
      expire_end:'',
      avl_start:'',
      avl_end:'',
      room_min:'',
      room_max:'',
      amn_id:[],
      fac_id: []
      
   }
   searchQuery.value = ''
   unit.value = '';
   statusId.value = '';
   floor.value = '';
   type.value = '';
   building.value = '';
   bedroom.value = '';
   sqm.value = '';
   price_sale.value = '';
   propertyfilter.value = '';
   ownerfilter.value = '';
   statusId.value = '';
   advfilter.value = blankData

   refetchData()
    store.commit('propertyConfig/FILTER_PROPERTY_CLEAR');
  
    
  
  }
  const fetchTemplates = (filter)=>{
    
   
    searchQuery.value = filter.keyword;
    type.value = filter.type_name;
    propertyfilter.value = filter.propertyfilter;
    ownerfilter.value = filter.ownerfilter;
    floor.value = filter.floor;
    building.value = filter.building;
    bedroom.value = filter.bedroom;
    unit.value = filter.unit;
    sqm.value = filter.sqm;
    statusId.value = filter.status_id;
    refetchData();
   

  }
  const SearchAdvanced = (filter)=>{  
    advfilter.value = filter;
    bedroom.value = filter.bedroom;
   
    refetchData();

  }
  const fetchProperties = (ctx, callback)=>{
   //var date = new Date(new Date().setDate(new Date().getDate() - 30));

    const offset = perPage.value*(currentPage.value-1);
    const sortdir =  'DESC';
    let zone_id=null;
    let zones = null;
    let facs = '';
    if(advfilter.value.fac_id.length > 0){
      facs = advfilter.value.fac_id.join(',');
    }
    if(advfilter.value.zones.length > 1){
      zones = advfilter.value.zones.join(',');
    }else if(advfilter.value.zones.length == 1){
      zone_id = advfilter.value.zones[0];

    }
   
    let expire_start = advfilter.value.expire_start;
    let expire_end = advfilter.value.expire_end;
    let avl_start = advfilter.value.avl_start;
    let avl_end = advfilter.value.avl_end;
    let bts_id=null;
    let bts_ids = null;
    if(advfilter.value.bts_ids.length > 1){
      bts_ids = advfilter.value.bts_ids.join(',');
    }else if(advfilter.value.bts_ids.length == 1){
      bts_id = advfilter.value.bts_ids[0];

    }
    let mrt_id=null;
    let mrt_ids = null;
    if(advfilter.value.mrt_ids.length > 1){
      mrt_ids = advfilter.value.mrt_ids.join(',');
    }else if(advfilter.value.mrt_ids.length == 1){
      mrt_id = advfilter.value.mrt_ids[0];

    }
    if(expire_c.value){
      let date = new Date();
      let date2 = new Date(Date.now() + 60 * 24 * 60 * 60 * 1000);
      expire_start = formatDatea(date);
      expire_end = formatDatea(date2);
      //formatDatea
      
    }
    //facility
   /*
    params.salePricemin,
      params.salePricemax,
      params.rentPricemin,
      params.rentPricemax,
      params.sqm_min,
      params.sqm_max,
   */

    store
    .dispatch('app-property/fetchProperties', {
      perpage: perPage.value,
      offset:offset,
      bedroom:bedroom.value,
      type_name:type.value,
      unit:unit.value,
      sqm:sqm.value,
      statusId:statusId.value==="1"?statusId.value:null,
      keyword:searchQuery.value,
      propertyfilter:propertyfilter.value,
      ownerfilter:ownerfilter.value,
      floor:floor.value,
      building:building.value,
      action_id:statusFilter.value?statusFilter.value['id']:null,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      zones:zones,
      zone_id:zone_id,
      rentPricemin:advfilter.value.rent_min,
      rentPricemax:advfilter.value.rent_max,
      salePricemin:advfilter.value.sale_min,
      salePricemax:advfilter.value.sale_max,
      sqm_min:advfilter.value.room_min,
      sqm_max:advfilter.value.room_max,
      bts_ids:bts_ids,
      bts_id:bts_id,
      mrt_ids:mrt_ids,
      
      mrt_id:mrt_id,
      facility:facs,
      expire_start:expire_start,
      expire_end:expire_end,
      avl_start:avl_start,
      avl_end:avl_end,

     
    
    })
    .then(response => {
      
     
      const properties = response.data.data;
      let props = properties.map(prop=>{
        
        prop.availble_date = formatDatea(prop.availble_date);
        if(prop.expire_date){
          prop.expire_date = formatDatea(prop.expire_date);
        }

        return prop;
      })

      let queryURL = {}

      if (propertyfilter.value) queryURL.propertyfilter = propertyfilter.value;
      if (unit.value) queryURL.unit = unit.value;
      if (ownerfilter.value) queryURL.ownerfilter = ownerfilter.value;
      if (type.value) queryURL.type = type.value;
      if (building.value) queryURL.building = building.value;
      if (bedroom.value) queryURL.bedroom = bedroom.value;
      if (sqm.value) queryURL.sqm = sqm.value;
      if(perPage.value) queryURL.perpage = perPage.value;
      if(offset) queryURL.offset = offset;
      if(type.value) queryURL.type_name = type.value;
      if(statusId.value) queryURL.statusId = statusId.value;
      if(searchQuery.value) queryURL.keyword = searchQuery.value;
      if(sortBy.value) queryURL.sortBy = sortBy.value;
      if(isSortDirDesc.value) queryURL.sortDesc = isSortDirDesc.value;
      if(zones) queryURL.zones = zones;
      if(zone_id) queryURL.zone_id = zone_id;
      if(bts_ids) queryURL.bts_ids = bts_ids;
      if(bts_id) queryURL.bts_id = bts_id;
      if(mrt_ids) queryURL.mrt_ids = mrt_ids;
      if(mrt_id) queryURL.mrt_id = mrt_id;
      if(advfilter.value.rent_min) queryURL.rentPricemin = advfilter.value.rent_min;
      if(advfilter.value.rent_max) queryURL.rentPricemax = advfilter.value.rent_max;
      if(advfilter.value.sale_min) queryURL.salePricemin = advfilter.value.sale_min;
      if(advfilter.value.sale_max) queryURL.salePricemax = advfilter.value.sale_max;
      if(expire_start) queryURL.expire_start = expire_start;
      if(expire_end) queryURL.expire_end = expire_end;
      if(avl_start) queryURL.avl_start = avl_start;
      if(avl_end) queryURL.avl_end = avl_end;
      if(advfilter.value.room_min) queryURL.sqm_min = advfilter.value.room_min;
      if(advfilter.value.room_max) queryURL.sqm_max = advfilter.value.room_max;
      if(facs) queryURL.fac_id = facs;
      if (floor.value) queryURL.floor = floor.value;

      const currentQuery = router.currentRoute.query;
      const newQueryString = new URLSearchParams(queryURL).toString();

      if (newQueryString !== new URLSearchParams(currentQuery).toString()) {
        router.replace({ query: queryURL });
      }

    callback(props)
     // callback(invoices)
     totalProperty.value = response.data.total
    })
    .catch((error) => {
      toast({
        component: ToastificationContent,
        props: {
          title: "Error fetching properties' list",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
    
  }
  store
    .dispatch('app-property/fetchgetActions', {})
    .then(response => {
      const action = response.data.data;
      actions.value =action;
      
     
     
     
    })
    .catch(() => {
    
    })
    const addProductInCart = (user_id, property_id) => {
      store.dispatch('app-ecommerce/addProductInCart', { user_id,property_id }).then(response=>{
        if(response.data.success == false){
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })

        }else{
          toast({
            component: ToastificationContent,
            props: {
              title: "Add Property Cart Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', store.state['app-ecommerce'].cartItemsCount + 1)

        }
        
        
       
      }).catch((err)=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Error Add Property",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
  
      })
      //return store.dispatch('app-ecommerce/addProductInCart', { user_id,property_id })
    }

  const fetchInvoices = (ctx, callback) => {
    store
      .dispatch('app-property/fetchInvoices', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { invoices, total } = response.data

        callback(invoices)
        totalInvoices.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceStatusVariantAndIcon = status => {
    if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' }
    if (status === 'Paid') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
    if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
    if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
    if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveClientAvatarVariant = status => {
    if (status === 'Partial Payment') return 'primary'
    if (status === 'Paid') return 'danger'
    if (status === 'Downloaded') return 'secondary'
    if (status === 'Draft') return 'warning'
    if (status === 'Sent') return 'info'
    if (status === 'Past Due') return 'success'
    return 'primary'
  }
  const formmail_show = (params)=>{
    isMailFormSidebarActive.value =true;
    propertyData.value = params;

  }
  const showModalImage = (item)=>{
    isModalFilesSelectedShow.value = true;
    imagePosition.value = item;

  }
  const updateFolder = async(item)=>{
    isModalFilesSelectedShow.value = false;
    await store.dispatch('app-property/fetchUpdatePropertyFolder',item);
    refetchData();
  }

  return {
    fetchInvoices,
    fetchProperties,
    tableColumns,
    tableColumns_property,
    perPage,
    currentPage,
    totalProperty,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPropertyListTable,
    unit,
    floor,
    type,
    building,
    bedroom,
    sqm,
    statusId,
    price_rent,
    price_sale,
    statusFilter,
    propertyfilter,
    ownerfilter,
    deleteData,
    addProductInCart,
    resolveInvoiceStatusVariantAndIcon,
    resolveClientAvatarVariant,
    actions,
    clearData,
    saveSearch,
    fetchTemplates,
    refetchData,
    updateData,
    propertyData,
    isMailFormSidebarActive,
    formmail_show,
    SearchAdvanced,
    advfilter,
    expire_c,
    isModalFilesSelectedShow,
    imagePosition,
    showModalImage,
    updateFolder,
  }
}
