<template>
<b-modal 
    hide-footer
    :visible="isModalFilesSelectedShow"
    size="lg"
    @change="(val) => $emit('update:is-modal-files-selected-show', val)"
    @shown="getData(Data)"
>
    <template #modal-title>
        Storage
    </template>
    <div  class="list-group d-flex flex-wrap   list-group-horizontal ">
        <template
        v-for="(item, index) in folders"
      >
        <b-list-group-item 
          v-if="!item.types"
          :key="'folder-'+index"
          tag="li"
          class="border-0 text-center p-2"
         
        
        >
        <div  
        @dblclick="openFolder(item)"
        >
        <b-form-checkbox v-model="folder_selected" :value="item.id" />
        <feather-icon
              icon="FolderIcon"
              size="62"
            />
          <div class="text-center">
              <strong> {{ item.title }}</strong>
          </div>

        </div>
        
        
          
        </b-list-group-item>
        <b-list-group-item 
          v-else
          :key="'file-'+index"
          tag="li"
          class="border-0 text-center p-2"
        
        
        >
       
        <div >
          
        <b-img
            :src="item.fullpath"
            v-if="item.types == 'i'"
            style="height: 62px;"
            thumbnail
            
          />
        
            <feather-icon
              icon="FileIcon"
              size="62"
              v-else
            />
          <div class="text-center">
              <strong> {{ item.title }}</strong>
          </div>

        </div>
        
          
        </b-list-group-item>
        </template>
        
        
    </div>
    <div class="mt-2">
        <b-button   v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="outline-success" @click="addFiles(Data)">Save</b-button>
    </div>
   
</b-modal>
</template>
<script>

import { BButton, BFormCheckbox, BModal, BForm, BFormGroup, BFormInput, BFormInvalidFeedback,  BListGroup,BListGroupItem,BBreadcrumb, BBreadcrumbItem,BImg } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

//api
import store from '@/store'
import { ref,onUnmounted } from '@vue/composition-api'
//alerty
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import folderStoreModule from '@/views/files/folderStoreModule'
import filesStoreModule from '@/views/files/filesStoreModule'
export default {
    model: {
        prop: 'isModalFilesSelectedShow',
        event: 'update:is-modal-files-selected-show',
    },
    props: {
        isModalFilesSelectedShow: {
          type: Boolean,
          required: true,
        },
        Data:{
            type: Object
        }
       
     
      
    },
    components: {
        BButton,
        BModal,
        BForm, BFormGroup, BFormInput, BFormInvalidFeedback,
          BListGroup,BListGroupItem,BBreadcrumb, BBreadcrumbItem,
          BImg,BFormCheckbox
    },
    directives: {
        Ripple,
    },
    setup(props, { emit }){
        const FOLDER_STORE_MODULE_NAME = 'files-folder'
        if (!store.hasModule(FOLDER_STORE_MODULE_NAME)) store.registerModule(FOLDER_STORE_MODULE_NAME, folderStoreModule)
        onUnmounted(() => {
            if (store.hasModule(FOLDER_STORE_MODULE_NAME)) store.unregisterModule(FOLDER_STORE_MODULE_NAME)
        });
        const FILES_STORE_MODULE_NAME = 'app-files'
        if (!store.hasModule(FILES_STORE_MODULE_NAME)) store.registerModule(FILES_STORE_MODULE_NAME, filesStoreModule)
        onUnmounted(() => {
            if (store.hasModule(FILES_STORE_MODULE_NAME)) store.unregisterModule(FILES_STORE_MODULE_NAME)
        });
        const toast = useToast();
        const parent = ref(0);
        const folders = ref([]);
        const folder_selected = ref(null);

        const getData = async(data)=>{
            console.log('data-prop',data)
            parent.value = 0;
            folder_selected.value = data.folder_id
         
            getAll();
            

        }
        const getAll = async()=>{
            folders.value = []
            let filter = {
                parent:parent.value,
                order_by:'ordering_count ASC'
            }
           
            const frds = await store.dispatch('files-folder/fetchData',filter);
            if(frds.data.success){
                folders.value = frds.data.data;
                //console.log('folder-result',frds.data.data);
            }
        }
        const openFolder = (item)=>{
            parent.value = item.id;
            
           // breadcrumbsData.value.push(item)
            getAll();

        }
        const addFiles = (item)=>{
            let dat = {
                id:item.id,
                folder_id:folder_selected.value
            }
            //\console.log('files_selected',files_selected.value);
            emit('save-folder-id',dat);

        }

        return {
            getData,
            parent,
            getAll,
            folders,
            openFolder,
            addFiles,
            folder_selected
        }
    }
    
}
</script>