<template>
    <b-card-actions title="Advanced Search"
          action-collapse :collapsed="true">
    <b-row>
        <b-col md="3">
            <label
            for="sb-days"
            class="mt-2"
          >Bedroom</label>
          <b-form-spinbutton
            id="sb-days"
            v-model="Data.bedroom"
            placeholder="--"
            :formatter-fn="bedroomFormatter"
            min="0"
            max="10"
            wrap
          />
        </b-col>
        <b-col md="3">
            <label
            for="sb-days"
            class="mt-2"
            >Zone</label>
            <v-select
              v-model="Data.zones"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="name"
              :reduce="(option) => option.id"
              :options="zones"
            />
        </b-col>
        <b-col md="3">
            <label
            for="sb-days"
            class="mt-2"
            >Bts</label>
            <v-select
              v-model="Data.bts_ids"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="name"
              :reduce="(option) => option.id"
              :options="bts"
            />
        </b-col>
        <b-col md="3">
            <label
            for="sb-days"
            class="mt-2"
            >Mrt</label>
            <v-select
              v-model="Data.mrt_ids"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="name"
              :reduce="(option) => option.id"
              :options="mrt"
            />
        </b-col>
         <b-col md="3">
            <label
             
              class="mt-2"
            >Rent Min</label>
            <b-form-input v-model="Data.rent_min" />
        </b-col>
        <b-col md="3">
          <label
              
              class="mt-2"
            >Rent Max</label>
            <b-form-input v-model="Data.rent_max" />
        </b-col>
        <b-col md="3">
            <label
             
              class="mt-2"
            >Sale Min</label>
            <b-form-input v-model="Data.sale_min" />
        </b-col>
        <b-col md="3">
          <label
              
              class="mt-2"
            >Sale Max</label>
            <b-form-input v-model="Data.sale_max" />
        </b-col>
        <b-col md="3">
            <label
          
            class="mt-2"
            >Expire Start date</label>
            <b-form-datepicker
            v-model="Data.expire_start"
            today-button
            reset-button
            close-button
            locale="en"
          />
            
        </b-col>
        <b-col md="3">
            <label
          
            class="mt-2"
            >Expire End date</label>
            <b-form-datepicker
            v-model="Data.expire_end"
            today-button
            reset-button
            close-button
            locale="en"
          />
            
        </b-col>
        <b-col md="3">
            <label
          
            class="mt-2"
            >Avail Start date</label>
            <b-form-datepicker
            v-model="Data.avl_start"
            today-button
            reset-button
            close-button
            locale="en"
          />
        </b-col>
        <b-col md="3">
            <label
          
            class="mt-2"
            >Avail End date</label>
            <b-form-datepicker
            v-model="Data.avl_end"
            today-button
            reset-button
            close-button
            locale="en"
          />
            
        </b-col>
        <b-col md="3">
            <label
             
              class="mt-2"
            >Room Min Size</label>
            <b-form-input v-model="Data.room_min" />
        </b-col>
        <b-col md="3">
          <label
              
              class="mt-2"
            >Room Max Size</label>
            <b-form-input v-model="Data.room_max" />
        </b-col>
    </b-row>
    <b-tabs>
      <b-tab title="Amenity">
        
                <b-form-checkbox-group
                 
                    v-model="Data.fac_id"
                 
                    :options="amnls"
                    text-field="name"
                    value-field="id"
                    name="amn_id"
                    class="demo-inline-spacing "
                />
               
      </b-tab>
      <b-tab title="Facility">
        <b-form-checkbox-group
                
                    v-model="Data.fac_id"
                 
                    :options="facs"
                    text-field="name"
                    value-field="id"
                    name="amn_id"
                    class="demo-inline-spacing "
                />
      </b-tab>
    </b-tabs>
    <div class="d-flex mt-2">
             <b-button
               v-ripple.400="'rgba(255, 255, 255, 0.15)'"
               variant="primary"
               class="mr-2"
               type="button"
               @click="applyFilter"
             >
               Apply Filter
             </b-button>
             <b-button
               v-ripple.400="'rgba(255, 255, 255, 0.15)'"
               variant="outline-danger"
               class="mr-2"
               type="button"
               @click="clearFilter"
             >
               Clear
             </b-button>
            
           </div>

    </b-card-actions>
</template>
<script>
import axios from '@axios'
import { ref } from '@vue/composition-api'
import {
  BCard, BTabs,BTab, BFormGroup, BFormCheckboxGroup, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BMedia, BAvatar, BLink,BFormSpinbutton,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BImg,VBToggle,BSidebar,BButtonToolbar, BButtonGroup,BFormDatepicker
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import router from '@/router'

export default {
    components: {
        BCard,BTabs,BTab, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BMedia, BAvatar, BLink,BFormSpinbutton,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BImg,VBToggle,BSidebar,BButtonToolbar, BButtonGroup,
  BCardActions,BFormDatepicker,
  vSelect,BFormGroup, BFormCheckboxGroup
    },
    data: () => ({
    value: '',
    bedrooms: ['Studio', '1', '2', '3', '4', '5', '6','7','8','9','10++'],
  }),
  directives: {
     Ripple,
   },
  setup(props, { emit }) {
    //emit('refetch-data')
    const places = ref([]);
    const zones = ref([]);
    const bts = ref([]);
    const mrt = ref([]);
    const amnls = ref([]);
    const facs = ref([]);
    const facilities = ref([])

    const blankData = {
          bedroom: router.currentRoute.query.bedroom? Number(router.currentRoute.query.bedroom): '',
          place_id:[],
          zone_id: router.currentRoute.query.zone_id != null? router.currentRoute.query.zone_id: '',
          zones: router.currentRoute.query.zones? router.currentRoute.query.zones.split(',').map(Number): router.currentRoute.query.zone_id? [Number(router.currentRoute.query.zone_id)]: [],
          bts_id: router.currentRoute.query.bts_id != null? router?.currentRoute?.query?.bts_id: '',
          bts_ids: router.currentRoute.query.bts_ids? router.currentRoute.query.bts_ids.split(',').map(Number): router.currentRoute.query.bts_id? [Number(router.currentRoute.query.bts_id)]: [],
          mrt_id: router.currentRoute.query.mrt_id != null? router?.currentRoute?.query?.mrt_id: '',
          mrt_ids: router.currentRoute.query.mrt_ids? router?.currentRoute?.query?.mrt_ids.split(',').map(Number): router.currentRoute.query.mrt_id? [Number(router.currentRoute.query.mrt_id)]: [],
          rent_min: router.currentRoute.query.rentPricemin? router.currentRoute.query.rentPricemin: '',
          rent_max: router.currentRoute.query.rentPricemax? router.currentRoute.query.rentPricemax: '',
          sale_min: router.currentRoute.query.salePricemin? router.currentRoute.query.salePricemin: '',
          sale_max: router.currentRoute.query.salePricemax? router.currentRoute.query.salePricemax: '',
          expire_start: router.currentRoute.query.expire_start? router.currentRoute.query.expire_start: '',
          expire_end: router.currentRoute.query.expire_end? router.currentRoute.query.expire_end: '',
          avl_start: router.currentRoute.query.avl_start? router.currentRoute.query.avl_start: '',
          avl_end: router.currentRoute.query.avl_end? router.currentRoute.query.avl_end: '',
          room_min: router.currentRoute.query.sqm_min? router.currentRoute.query.sqm_min: '',
          room_max: router.currentRoute.query.sqm_max? router.currentRoute.query.sqm_max: '',
          amn_id:[],
          fac_id: router.currentRoute.query.fac_id? router?.currentRoute?.query?.fac_id.split(',').map(Number): []
       }
    const Data = ref(JSON.parse(JSON.stringify(blankData)))
    const getPlces = async ()=>{
      var data = JSON.stringify({
        "keyword": ''
      });
      const pls = await axios.post('autocomplete/v1/place',data);
      places.value = pls.data.data;
      
    }
    getPlces();
    const getZones = async ()=>{
      var data = JSON.stringify({
        perpage: 2000,
              offset:0
      });
      const zons = await axios.post('zone/v1/lists',data);
      zones.value = zons.data.data;
      
    }
    getZones();
    const getbts = async ()=>{
      let btses = await axios.get('transit/v1/listtype/bts');
      bts.value = btses.data.data;
      
    }
    getbts();
    const getmrt = async ()=>{
      let mrts = await axios.get('transit/v1/listtype/mrt');
      mrt.value = mrts.data.data;
      
    }
    getmrt();

    const getAMns = async ()=>{
      let queryParams = {
         perpage: 100,
          offset:0,
          type_code:'2',
          keyword:null
      }
     
      var data = JSON.stringify(queryParams);
      let amns = await axios.post('facility/v1/lists',data);
      amnls.value = amns.data.data;
    }
    getAMns();
    const getFacs = async ()=>{
      let queryParams = {
         perpage: 100,
          offset:0,
          type_code:'1',
          keyword:null
      }
     
      var data = JSON.stringify(queryParams);
      let fac = await axios.post('facility/v1/lists',data);
      facs.value = fac.data.data;
    }
    getFacs();
    const  applyFilter = ()=>{
      emit('refetch-data', Data.value)

    }
    const  clearFilter = ()=>{
      const blankData = {
        bedroom:'',
        zone_id:[],
    zones:[],
    place_id:[],
    bts_id:[],
    bts_ids:[],
    mrt_ids:[],
    mrt_id: '',
    rent_min:'',
    rent_max:'',
    sale_min:'',
    sale_max:'',
    expire_start:'',
    expire_end:'',
    avl_start:'',
    avl_end:'',
    room_min:'',
    room_max:'',
    amn_id:[],
    fac_id:[]
       }
      Data.value = blankData;
      emit('refetch-data', Data.value)
    }
    
    
    return {
      applyFilter,
      clearFilter,
      Data,
      places,
      zones,
      bts,
      mrt,
      amnls,
      facs,
      facilities
    }
  },
  methods: {
    bedroomFormatter(value) {
      return this.bedrooms[value]
    },
  },
    
}
</script>